import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/tab';
import './components/menu_header';

import Glide, {Swipe, Controls, Breakpoints, Images,Autoplay} from '@glidejs/glide/dist/glide.modular.esm';

let config = {
    perView : 2.2,
    type : 'carousel',
    gap : '19px',
    breakpoints : {
        599 :{
            perView:1.1,
        },
        833 :{
            perView:1.5,
        },
        1365 : {
            perView : 2.2,
        }
    }

}

let slideReview = document.querySelector('.slide-reviews');
let glideReview = new Glide(slideReview,config);

glideReview.mount({
    Swipe,
    Controls,
    Breakpoints,
    Images,
    Autoplay
});

let configGallery = {
    perView : 1,
    type : 'carousel'
}

let slideGallery = document.querySelector('.sec-b-gallery');
let glideGallery = new Glide(slideGallery,configGallery);

glideGallery.mount({
    Swipe,
    Controls,
    Breakpoints,
    Images,
    Autoplay
});


const formContact = document.getElementById("form-contact");
let flagSend = true;

formContact.addEventListener("submit",event => {
   event.preventDefault();
   const message = document.querySelector(".message-form");
   let formData = new FormData(event.target);

   if (flagSend === true){
        fetch(HOST, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
            },
            method: "post",
            credentials: "same-origin",
            body: JSON.stringify(Object.fromEntries(formData.entries()))
        }).then(result => result.json()).then(function(json){
            message.classList.remove("d-none");
            setTimeout(() => {
                message.classList.add("d-none");
                formContact.reset();
            },3000);
        });
   }
});
