const language = document.querySelector(".language");
const menu = document.querySelector(".menu-right");
const openmenu = document.querySelector(".sub-menu");
const openlang = document.querySelector(".lang-main");
const footer = document.getElementById("footerPv");
const header = document.getElementById("headerPv");

openlang.addEventListener('click',evt => {
    if(!language.classList.contains('open')){
        language.classList.add('open');
    }else{
        language.classList.remove('open');
    }
});

openmenu.addEventListener('click',evt => {
    if(language.classList.contains('open')){
        language.classList.remove('open');
    }

    if(!menu.classList.contains('open')){
        menu.classList.add('open');
    }else{
        menu.classList.remove('remove');
    }
});

document.querySelector('.close-menu').addEventListener('click',evt => {
   menu.classList.remove('open');
});

let scrollEl = (link,evt) => {
    let content = evt.target.dataset.section;

    if(typeof  content !== 'undefined'){
        document.querySelector('.'+content).scrollIntoView({
            top:-90,
            behavior: 'smooth',
            block: 'start'
        });
    }
    menu.classList.remove('open');

    return true;
}

document.querySelectorAll("header .nav .nav-link").forEach(el =>{
    el.addEventListener('click',evt => {
        let content = evt.target.dataset.section;
        document.querySelector('.'+content).scrollIntoView({
            top:-90,
            behavior: 'smooth',
            block: 'start'
        });
    });
});

document.querySelectorAll("header .menu-right .nav-link").forEach(el =>{
    el.addEventListener('click',evt => {scrollEl(el,evt)});
});


document.body.addEventListener("click",evt => {
   const exclusions = [
        openmenu,
        openlang
   ]

    if(exclusions.indexOf(evt.target) == -1){
       if(!menu.contains(evt.target)){
           if(menu.classList.contains('open')){
               menu.classList.remove('open');
           }
       }

        if(!language.contains(evt.target)){
            if(language.classList.contains('open')){
                language.classList.remove('open');
            }
        }
    }
});

document.addEventListener("scroll",(evt) => {
    let currentScroll = document.scrollingElement.scrollTop;
    let position = footer.offsetTop - 65;

    if(currentScroll > position){
        header.classList.add("close");
    }else{
        header.classList.remove("close");
    }


},false);

document.querySelectorAll(".link-body").forEach(el => {
    el.addEventListener("click",evt => {
        let item = evt.target

        if(!evt.target.classList.contains('.link-body')){
           item = evt.target.closest(".link-body")
        }
        const contain = item.dataset.target;

        document.querySelector('.'+contain).scrollIntoView({
            top:-90,
            behavior: 'smooth',
            block: 'start'
        });
    });
});
